import { Students } from "classes/models/students/student.model";
import { StudentsService } from "~/classes/models/students/students.service";

export const useStudentsClassroomsData = (userId: string) =>
  defineStore(`/users/${userId}/students/classrooms/data`, () => {
    const appUserDataStore = useAppUserData();
    const { studentPermissions } = storeToRefs(appUserDataStore);

    const activeStudentId = ref<string>();
    const students = ref<Students>([]);

    const initializeStudentsClassroomData = async () => {
      let studentIds = [] as string[];
      const pseduoStudentId = useTeacherPesudoStudentLogin().pseudoStudentId;

      if (pseduoStudentId != undefined) {
        studentIds.push(pseduoStudentId);
      } else {
        studentIds = studentPermissions.value;
      }

      for (const studentId of studentIds) {
        const studentClassroomDataStore =
          useStudentClassroomDataStore(studentId);
        await studentClassroomDataStore.initializeStudentClassroomData();
      }

      if (studentIds.length == 0) {
        return;
      }

      activeStudentId.value = studentIds[0];

      students.value = [];

      // get each student
      for (const studentId of studentIds) {
        const student = await StudentsService.get(studentId);
        if (student.isArchived != true) {
          students.value.push(student);
        }
      }

      students.value = students.value.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    };

    const changeActiveStudent = (studentId: string) => {
      activeStudentId.value = studentId;
    };

    return {
      initializeStudentsClassroomData,
      changeActiveStudent,
      activeStudentId,
      students,
    };
  });
